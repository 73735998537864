import { useEffect } from "react";

export default function Footer() {
	return (
		<div className="mx-auto my-10 text-center flex flex-col gap-4">
			<p className="text-2xl font-bold">sign up for my newsletter:</p>
			<SubstackWidget />
			<p>❤️ Zoë Hong</p>
		</div>
	);
}

// Extend the Window interface to include CustomSubstackWidget
declare global {
	interface Window {
		CustomSubstackWidget: {
			substackUrl: string;
			placeholder: string;
			buttonText: string;
			theme: string;
			colors: {
				primary: string;
				input: string;
				email: string;
				text: string;
			};
		};
	}
}

const SubstackWidget = () => {
	useEffect(() => {
		window.CustomSubstackWidget = {
			substackUrl: "zoehong.substack.com",
			placeholder: "example@gmail.com",
			buttonText: "Sign Up",
			theme: "custom",
			colors: {
				primary: "#000000",
				input: "#FFFFFF",
				email: "#444444",
				text: "#FFFFFF",
			},
		};

		const script = document.createElement("script");
		script.src = "https://substackapi.com/widget.js";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return <div id="custom-substack-embed" />;
};
