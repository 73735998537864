import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	isRouteErrorResponse,
	useRouteError,
} from "@remix-run/react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/remix";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./tailwind.css";

function App() {
	return (
		<html lang="en">
			<head>
				<Meta />
				<Links />
			</head>
			<body className="bg-[#fbf6ec] text-[#333] font-sans antialiased min-h-screen">
				<div className="container mx-auto px-2 py-2 flex flex-col gap-5 md:gap-20">
					<Header />
					<Outlet />
					<Footer />
				</div>
				<Scripts />
				<Analytics />
				<SpeedInsights />
			</body>
		</html>
	);
}

export default withSentry(App);

export const links: LinksFunction = () => [
	{ rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
	{ rel: "canonical", href: "https://zoehong.com" },
	{ rel: "icon", type: "image/png", href: "/favicon.png" },
	{ rel: "apple-touch-icon", href: "/apple-touch-icon.png" },
];

export const meta: MetaFunction = ({ location }) => {
	const title = "Zoë Hong — teacher, fashion design & illustration";
	const description =
		"My name is Zoe Hong and I teach: * fashion design process * figure drawing * fashion illustration * fashion industry best practices * general illustration basics * color theory * technical design * garment construction";
	const siteURL = "https://zoehong.com";
	const imageURL = "https://zoehong.com/apple-touch-icon.png";
	return [
		{ charSet: "utf-8" },
		{ name: "viewport", content: "width=device-width, initial-scale=1" },
		{ title },
		{ name: "description", content: description },
		{ property: "og:title", content: title },
		{ property: "og:description", content: description },
		{ property: "og:image", content: imageURL },
		{ property: "og:url", content: siteURL },
		{ property: "og:type", content: "website" },
		{ property: "og:site_name", content: "Zoë Hong" },
		{ name: "twitter:card", content: "summary_large_image" },
		{ name: "twitter:image", content: imageURL },
		{ name: "twitter:site", content: "@zoehong" },
		{ name: "twitter:creator", content: "@zoehong" },
		{ name: "twitter:title", content: title },
		{ name: "twitter:description", content: description },
		{ name: "twitter:url", content: siteURL },
	];
};

export function ErrorBoundary() {
    const error = useRouteError();
    const is404 = isRouteErrorResponse(error) && error.status === 404;

    captureRemixErrorBoundaryError(error);

    return (
		<html lang="en">
			<head>
				<title>Oh no!</title>
				<Meta />
				<Links />
			</head>
			<body>
				{is404 ? (
					<h1 className="text-center">
						<span className="text-4xl font-normal">404 </span>
						<span className="text-4xl font-bold">Not Found</span>
					</h1>
				) : (
					<h1 className="text-center">
						<span className="text-4xl font-bold">On ho! </span>
						<span className="text-4xl font-normal">Something went wrong!</span>
					</h1>
				)}
				<Scripts />
			</body>
		</html>
	);
}