export default function Header() {
	return (
		<div className="space-y-4">
			<TopLinks />

			<div>
				<img
					src="/images/zoehong.jpg"
					alt="Zoë Hong logo"
					className="w-48 h-48 mx-auto rounded-lg shadow-md"
				/>
			</div>

			<h1 className="mx-auto text-center text-2xl font-semibold">
				Zoe Hong is a fashion designer, illustrator, teacher, and author.
			</h1>
		</div>
	);
}

function TopLinks() {
	return (
		<div className="grid md:grid-cols-3 w-full my-8 gap-y-4">
			<div className="mx-auto flex flex-row flex-nowrap gap-4 my-auto font-semibold">
				<a href="/" type="button">
					Home
				</a>
				<a href={"https://shop.zoehong.com/"} target="shopify">
					Shop
				</a>
				<a href="/bio" type="button">
					Bio
				</a>
				<a href="/faq" type="button">
					FAQ
				</a>
				<a href="/contact">Contact</a>
			</div>

			<div className="mx-auto text-2xl font-semibold my-auto">
				<a href={"https://zoehong.com"}>Zoë Hong</a>
			</div>

			<div className="mx-auto flex flex-row flex-nowrap gap-5 my-auto">
				<IconLink
					alt="Instagram"
					imageURL="/icons/instagram.png"
					url={"https://www.instagram.com/zoehong/"}
				/>
				<IconLink
					alt="Pinterest"
					imageURL="/icons/pinterest.png"
					url={"https://www.pinterest.com/zoehong/"}
				/>
				<IconLink
					alt="Facebook"
					imageURL="/icons/facebook.png"
					url={"https://www.facebook.com/groups/920367461450543/"}
				/>
				<IconLink
					alt="Bluesky"
					imageURL="/icons/bluesky.png"
					url={"https://bsky.app/profile/zoehong.bsky.social"}
				/>
				<IconLink
					alt="Discord"
					imageURL="/icons/discord.png"
					url={"https://discord.gg/H4kNE6eV64"}
				/>
			</div>
		</div>
	);
}

function IconLink({
	alt,
	imageURL,
	url,
}: {
	alt: string;
	imageURL: string;
	url: string;
}) {
	return (
		<a href={url} title={alt} target={alt}>
			<img src={imageURL} alt={alt} className="h-6 w-6" />
		</a>
	);
}
